import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import SModal from './SModal';
import VueResource from './VueResource';
import UserEventBus from './UserEventBus';
import GlobalLoading from './GlobalLoading';

export default function (Vue) {
  VueResource(Vue);
  Vue.use(SModal);
  Vue.use(Loading, {
    // props
    zIndex: 7000,
    color: '#2a3d93',
    canCancel: false,
  });
  Vue.use(CKEditor);
  Vue.use(UserEventBus);
  Vue.use(GlobalLoading);
}
