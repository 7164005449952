import Vue from 'vue';
import Vuex from 'vuex';
import vSteps from './VSteps';
import qualificationForm from './qualificationForm';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    vSteps,
    qualificationForm,
  },
});
