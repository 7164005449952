var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "s-mobile-menu__wrapper",
    class: {
      'is-active': _vm.isActive
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('transition', {
    attrs: {
      "name": _vm.transition
    }
  }, [_vm.menuPage === 'main' ? _c('div', {
    key: "step-1",
    staticClass: "s-mobile-menu__page"
  }, [_c('section', {
    staticClass: "s-mobile-menu__header"
  }, [_c('ul', {
    staticClass: "s-mobile-menu__header-items"
  }, [_vm.user ? [_c('li', {
    staticClass: "s-mobile-menu__item s-mobile-menu__item--green s-bold"
  }, [_vm._v("\n                " + _vm._s(_vm.user) + "\n              ")])] : [_c('a', {
    staticClass: "s-mobile-menu__item",
    attrs: {
      "href": _vm.urls.login
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('auth.sign_in')) + "\n              ")]), _vm._v(" "), _vm.urls.reg ? _c('a', {
    staticClass: "s-mobile-menu__item",
    attrs: {
      "href": _vm.urls.reg
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('auth.sign_up')) + "\n              ")]) : _vm._e()]], 2), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu__header-close",
    on: {
      "click": function click($event) {
        _vm.isActive = false;
      }
    }
  })]), _vm._v(" "), _vm.user ? _c('section', {
    staticClass: "s-mobile-menu__body"
  }, [_c('div', {
    staticClass: "s-mobile-menu-name-group-border"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu-name-group"
  }, [_vm._v(_vm._s(_vm.trans('cabinet.main_sections')))]), _vm._v(" "), _vm._l(_vm.mapMenu, function (item, index) {
    return [!item.submenu ? [_c('a', {
      key: index,
      staticClass: "s-mobile-menu__item",
      class: {
        's-bold s-c-green': item.active,
        'd-flex flex-wrap': item.isPurchased
      },
      attrs: {
        "href": item.url
      }
    }, [_c('span', {
      staticClass: "flex-fill",
      domProps: {
        "innerHTML": _vm._s(item.name)
      }
    }), _vm._v(" "), item.isPurchased ? _c('span', {
      staticClass: "d-flex justify-content-end"
    }, [_c('i', {
      staticClass: "s-ico-fa s-ico-fa--thm-lock"
    })]) : _vm._e()])] : _c('button', {
      key: index,
      staticClass: "s-mobile-menu__item s-mobile-menu__item--submenu-down",
      class: {
        's-mobile-menu__item--green': item.active,
        's-mobile-menu__item--submenu-up': item.activeSubmenu
      },
      domProps: {
        "innerHTML": _vm._s(item.name)
      },
      on: {
        "click": function click($event) {
          return _vm.setSubmenu(item);
        }
      }
    }), _vm._v(" "), _c('transition', {
      key: index,
      attrs: {
        "name": "slide"
      }
    }, [item.activeSubmenu ? _c('div', [_vm._l(item.submenu, function (subMenuItem, subMenuIndex) {
      return [_c('a', {
        key: subMenuIndex,
        staticClass: "s-mobile-menu__item s-mobile-menu__item--submenu-child",
        class: {
          's-bold s-c-green': subMenuItem.active
        },
        attrs: {
          "href": subMenuItem.url
        },
        domProps: {
          "innerHTML": _vm._s(subMenuItem.name)
        }
      })];
    })], 2) : _vm._e()])];
  }), _vm._v(" "), _c('a', {
    staticClass: "s-mobile-menu__item",
    attrs: {
      "href": _vm.urls.logout
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.logout')) + "\n          ")])], 2) : _vm._e(), _vm._v(" "), _c('section', {
    staticClass: "s-mobile-menu__footer"
  }, [_c('div', {
    staticClass: "s-mobile-menu-name-group-border"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu-name-group",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('cabinet.settings'))
    }
  }), _vm._v(" "), _c('ul', {
    staticClass: "s-mobile-menu__footer-items"
  }, [_c('button', {
    staticClass: "s-mobile-menu__item s-mobile-menu__item--settings-right",
    on: {
      "click": function click($event) {
        _vm.menuPage += '/langs';
      }
    }
  }, [_c('span', [_vm._v("\n                " + _vm._s(_vm.trans('cabinet.language')) + ": " + _vm._s(_vm.trans('label.lang.' + _vm.currentLocale)) + "\n              ")])]), _vm._v(" "), _c('button', {
    staticClass: "s-mobile-menu__item s-mobile-menu__item--settings-right",
    class: {
      's-process': _vm.loader
    },
    on: {
      "click": _vm.toRegions
    }
  }, [_vm._v("\n              " + _vm._s(_vm.trans('cabinet.region')) + ":   "), _c('span', [_vm._v(_vm._s(_vm.position))])]), _vm._v(" "), _c('div', {
    staticClass: "s-mobile-menu-name-group-border"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu-name-group",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('contact.technical_support_service'))
    }
  }), _vm._v(" "), _c('a', {
    staticClass: "s-mobile-menu__item d-flex justify-content-between",
    attrs: {
      "href": "tel:+77473397222"
    }
  }, [_c('span', [_c('div', {
    staticStyle: {
      "font-size": "22px"
    }
  }, [_c('span', [_vm._v("+7 747")]), _vm._v(" "), _c('strong', [_vm._v("339 72 22")])])]), _vm._v(" "), _c('div', {
    staticClass: "s-str-ico s-str-ico--thm-telephone",
    staticStyle: {
      "font-size": "19px"
    }
  })]), _vm._v(" "), _c('a', {
    staticClass: "s-mobile-menu__item d-flex justify-content-between",
    attrs: {
      "href": "tel:+77273397222"
    }
  }, [_c('span', [_c('div', {
    staticStyle: {
      "font-size": "22px"
    }
  }, [_c('span', [_vm._v("+7 727")]), _vm._v(" "), _c('strong', [_vm._v("339 72 22")])])]), _vm._v(" "), _c('div', {
    staticClass: "s-str-ico s-str-ico--thm-telephone",
    staticStyle: {
      "font-size": "19px"
    }
  })]), _vm._v(" "), _vm.showWhatsapp ? _c('a', {
    staticClass: "s-mobile-menu__item d-flex justify-content-between",
    attrs: {
      "href": "https://wa.me/+77003397222"
    }
  }, [_c('span', [_c('div', {
    staticStyle: {
      "font-size": "22px"
    }
  }, [_c('span', [_vm._v("+7 700")]), _vm._v(" "), _c('strong', [_vm._v("339 72 22")])]), _vm._v(" "), _c('span', {
    staticClass: "s-c-green",
    staticStyle: {
      "font-size": "14px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.trans('contact.phone_signature'))
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-ico-fab s-ico-fa--thm-whatsapp",
    staticStyle: {
      "font-size": "24px",
      "padding-right": "0.3em",
      "margin-bottom": "2.3rem"
    }
  })]) : _vm._e()])])]) : _vm._e(), _vm._v(" "), _vm.menuPage === 'main/langs' ? _c('div', {
    key: "step-2",
    staticClass: "s-mobile-menu__page"
  }, [_c('section', {
    staticClass: "s-mobile-menu__header"
  }, [_c('div', {
    staticClass: "s-mobile-menu__header-items"
  }, [_c('button', {
    staticClass: "s-mobile-menu__item s-mobile-menu__item--green s-bold ",
    on: {
      "click": _vm.routeUp
    }
  }, [_c('div', {
    staticClass: "s-mobile-menu__header_back"
  }, [_vm.user ? _c('span', {
    staticClass: "s-bold"
  }, [_vm._v("\n                  " + _vm._s(_vm.user))]) : _c('span', {
    staticClass: "s-bold"
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('label.menu')) + "\n                ")]), _vm._v(" "), _c('span', {
    staticClass: "s-ico s-ico--thm-arrow-right s-c-white"
  })])])])]), _vm._v(" "), _c('section', {
    staticClass: "s-mobile-menu__body"
  }, [_c('div', {
    staticClass: "s-mobile-menu-name-group-border"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu-name-group"
  }, [_vm._v(_vm._s(_vm.trans('cabinet.change_language')))]), _vm._v(" "), _vm._l(_vm.langs, function (lang, index) {
    return _c('a', {
      key: index,
      staticClass: "s-mobile-menu__item",
      class: {
        's-mobile-menu__item--selected': lang.locale === _vm.currentLocale
      },
      attrs: {
        "href": _vm.localityLink(lang)
      }
    }, [_vm._v("\n            " + _vm._s(lang.name) + "\n          ")]);
  })], 2)]) : _vm._e(), _vm._v(" "), _vm.menuPage.includes('regions') ? _c('div', {
    key: "step-3",
    staticClass: "s-mobile-menu__page"
  }, [_c('section', {
    staticClass: "s-mobile-menu__header"
  }, [_c('div', {
    staticClass: "s-mobile-menu__header-items"
  }, [_c('button', {
    staticClass: "s-mobile-menu__item s-mobile-menu__item--green s-bold s-mobile-menu__item--to-right",
    on: {
      "click": function click($event) {
        _vm.menuPage = 'main';
      }
    }
  }, [_c('div', {
    staticClass: "s-mobile-menu__header_back"
  }, [_vm.user ? _c('span', {
    staticClass: "s-bold"
  }, [_vm._v("\n                  " + _vm._s(_vm.user))]) : _c('span', {
    staticClass: "s-bold"
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('label.menu')) + "\n                ")]), _vm._v(" "), _c('span', {
    staticClass: "s-ico s-ico--thm-arrow-right s-c-white"
  })])]), _vm._v(" "), _c('div', {
    staticClass: "s-mobile-menu-name-group-border"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu-name-group"
  }, [_vm._v("1. " + _vm._s(_vm.trans('cabinet.area_selection')))]), _vm._v(" "), _vm.menuPage.includes('/depts') ? _c('button', {
    key: "step-1",
    staticClass: "s-mobile-menu__item s-mobile-menu__item--selected-region",
    on: {
      "click": function click($event) {
        _vm.menuPage = 'regions';
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.selected.region.short_name) + "\n            ")]) : _vm._e(), _vm._v(" "), _vm.menuPage.includes('/depts') ? _c('div', [_c('div', {
    staticClass: "s-mobile-menu-name-group-border"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu-name-group"
  }, [_vm._v("2. " + _vm._s(_vm.trans('cabinet.choice_of_area')))]), _vm._v(" "), !_vm.withoutLocality && _vm.menuPage.includes('/locs') ? _c('button', {
    key: "step-2",
    staticClass: "s-mobile-menu__item s-mobile-menu__item--selected-region",
    on: {
      "click": _vm.routeUp
    }
  }, [_c('span', [_vm._v("\n                  " + _vm._s(_vm.selected.dept.name) + "\n                ")])]) : _vm._e()]) : _vm._e()])]), _vm._v(" "), _c('section', {
    staticClass: "s-mobile-menu__body"
  }, [_c('transition', {
    attrs: {
      "name": _vm.transition
    }
  }, [_vm.withoutLocality && _vm.menuPage.includes('/depts') ? _c('div', {
    key: "step-2",
    staticClass: "s-mobile-menu__body-content"
  }, _vm._l(_vm.selected.region.depts, function (dept, index) {
    return _c('a', {
      key: index,
      staticClass: "s-mobile-menu__item",
      class: {
        's-mobile-menu__item--selected': _vm.currentPlace.dept === dept.name,
        's-process': _vm.loader && _vm.selected.dept === dept
      },
      attrs: {
        "href": "/".concat(_vm.currentLocale, "/").concat(_vm.currentRegion, "?department=").concat(dept.id)
      },
      on: {
        "click": function click($event) {
          _vm.selected.dept = dept;
          _vm.loader = true;
        }
      }
    }, [_vm._v("\n                " + _vm._s(dept.name) + "\n              ")]);
  }), 0) : !_vm.withoutLocality && _vm.menuPage.includes('/locs') ? _c('div', {
    key: "step-1",
    staticClass: "s-mobile-menu__body-content"
  }, [_c('div', {
    staticClass: "s-mobile-menu-name-group-border"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-mobile-menu-name-group"
  }, [_vm._v("3. " + _vm._s(_vm.trans('cabinet.choice_of_settlement')))]), _vm._v(" "), _c('a', {
    staticClass: "s-mobile-menu__item",
    class: {
      /* check if object is empty */
      's-process': _vm.loader && Object.keys(_vm.selected.loc).length === 0
    },
    attrs: {
      "href": "/".concat(_vm.currentLocale, "/").concat(_vm.currentRegion, "?department=").concat(_vm.selected.dept.id)
    },
    on: {
      "click": function click($event) {
        _vm.loader = true;
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('button.choose_department')) + "\n              ")]), _vm._v(" "), _vm._l(_vm.selected.dept.locs, function (loc, index) {
    return _c('a', {
      key: index,
      staticClass: "s-mobile-menu__item",
      class: {
        's-mobile-menu__item--selected': _vm.currentPlace.loc === loc.name,
        's-process': _vm.loader && _vm.selected.loc === loc
      },
      attrs: {
        "href": "/".concat(_vm.currentLocale, "/").concat(_vm.currentRegion, "?locality=").concat(loc.id)
      },
      on: {
        "click": function click($event) {
          _vm.selected.loc = loc;
          _vm.loader = true;
        }
      }
    }, [_vm._v("\n                " + _vm._s(loc.name) + "\n              ")]);
  })], 2) : _vm.menuPage.includes('/depts') ? _c('div', {
    key: "step-2",
    staticClass: "s-mobile-menu__body-content"
  }, _vm._l(_vm.selected.region.depts, function (dept, index) {
    return _c('button', {
      key: index,
      staticClass: "s-mobile-menu__item",
      class: {
        's-mobile-menu__item--selected': _vm.currentPlace.dept === dept.name,
        's-process': _vm.loader && _vm.selected.dept === dept
      },
      on: {
        "click": function click($event) {
          return _vm.selectParent(dept, 'dept', 'locs');
        }
      }
    }, [_vm._v("\n                " + _vm._s(dept.name) + "\n              ")]);
  }), 0) : _c('div', {
    key: "step-3",
    staticClass: "s-mobile-menu__body-content"
  }, [_vm.withoutDepartment ? _c('div', _vm._l(_vm.regions, function (region, index) {
    return _c('a', {
      key: index,
      staticClass: "s-mobile-menu__item",
      class: {
        's-mobile-menu__item--selected': _vm.currentPlace.region === region.short_name,
        's-process': _vm.loader && _vm.selected.region === region
      },
      attrs: {
        "href": "/".concat(_vm.currentLocale, "/").concat(_vm.currentRegion, "?region=").concat(region.id)
      }
    }, [_vm._v("\n                  " + _vm._s(region.short_name) + "\n                ")]);
  }), 0) : _c('div', _vm._l(_vm.regions, function (region, index) {
    return _c('button', {
      key: index,
      staticClass: "s-mobile-menu__item",
      class: {
        's-mobile-menu__item--selected': _vm.currentPlace.region === region.short_name,
        's-process': _vm.loader && _vm.selected.region === region
      },
      on: {
        "click": function click($event) {
          return _vm.selectParent(region, 'region', 'depts');
        }
      }
    }, [_vm._v("\n                  " + _vm._s(region.short_name) + "\n                ")]);
  }), 0)])])], 1)]) : _vm._e()])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }