import Vue from 'vue';
import _ from 'lodash';
import FakeFile from '@app_jumys/src/FakeFile';

class Helpers {
  urlGenerate(url, params) {
    let resultUrl = url;
    _.forEach(params, (value, key) => {
      resultUrl = resultUrl.replace(`:${key}`, value);
    });
    return resultUrl;
  }

  checkLoadingButton(button) {
    return button.classList.contains('loading-progress');
  }

  buttonLoader(button) {
    if (button.classList.contains('loading-progress')) {
      button.classList.remove('loading-progress');
    } else {
      button.classList.add('loading-progress');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handlerResponseErrorNew(vm, res) {
    if (res.errorType === 422 || res.errorType === 423) {
      const errors = [];
      const formErrors = {};
      _.forEach(res.data.errors, (value, key) => {
        errors.push(value);
        _.set(formErrors, key, value);
      });
      vm.errors = formErrors;
      if (errors.length) {
        vm.$notify({
          text: errors.join('<br>'),
          type: 'error',
        });
      }
    }
  }

  handlerResponseError({ data, status }, defaultCallback = () => {}) {
    if (!data && status === 200) {
      defaultCallback();
      return true;
    }
    if (status === 422 || status === 423) {
      let errors = '';
      _.forEach(data?.errors, (value) => {
        if (_.isArray(value)) {
          _.forEach(value, (message) => {
            errors += `${message}<br/>`;
          });
        } else {
          errors += `${value}<br/>`;
        }
      });
      Vue.notify({
        type: 'error',
        text: errors,
        duration: 5000,
      });
      return true;
    }
    if (data?.url_redirect) {
      window.onbeforeunload = null;
      window.location.href = data.url_redirect;
      return false;
    }
    if (data?.redirect !== undefined) {
      window.onbeforeunload = null;
      window.location.href = data.redirect;
      return false;
    }
    defaultCallback();
    return true;
  }

  showNotice(text, type = 'warn', title = window.L.error) {
    Vue.notify({
      type,
      title,
      text,
      duration: 5000,
    });
  }

  static showErrors({ data, status }) {
    if (status === 422 || status === 423) {
      let errors = '';
      _.forEach(data.errors, (value) => {
        errors += `${value}<br/>`;
      });
      Vue.notify({
        type: 'error',
        title: window.L.error,
        text: errors,
        duration: 5000,
      });
      // show_notice(errors, 'error')
      return errors;
    }
    console.warn(data);

    return null;
  }

  appendArray(formData, values, name) {
    if (values === true) {
      formData.append(name, 1);
    } else if ([0, false].includes(values)) {
      formData.append(name, 0);
    } else if (!values && name) {
      formData.append(name, '');
    } else if (values instanceof FakeFile) {
      formData.append(name, values.file, values.name);
    } else if (typeof values === 'object') {
      _.forEach(values, (value, key) => {
        if (typeof value === 'object') {
          this.appendArray(formData, value, `${name}[${key}]`);
        } else if (value !== null) {
          if (value instanceof FakeFile) {
            formData.append(name, value.file, value.name);
          } else {
            formData.append(`${name}[${key}]`, value);
          }
        }
      });
    } else {
      formData.append(name, values);
    }
  }

  genFormDataArray(obj) {
    const formData = new FormData();
    Object.keys(obj).forEach((item) => {
      this.appendArray(formData, obj[item], item);
    });
    return formData;
  }
}

const Helper = new Helpers();
export default Helpers;
export {
  Helper,
};
