export default {
  state: {
    hasDistrict: false,
  },
  mutations: {
    setHasDistrict(state) {
      state.hasDistrict = true;
    },
    resetHasDistrict(state) {
      state.hasDistrict = false;
    },
  },
};
