const Plugin = {
  install(_Vue, pluginOptions = {}) {
    const globalLoading = new _Vue({
      data: {
        count: 0,
        loader: null,
      },
      created() {
      },

      methods: {
        async show() {
          if (this.loader === null) {
            document.activeElement.blur();
            this.loader = this.$loading.show();
          }
          this.count += 1;
        },
        async hide(force = false) {
          this.count = this.count - 1;
          if (force || this.count <= 0) {
            if (this.loader !== null) {
              this.loader.hide();
              this.loader = null;
            }
            this.count = 0;
          }
        },
      },
    });
    _Vue.prototype.$globalLoading = globalLoading;
    _Vue.globalLoading = globalLoading;
  },
};

export default Plugin;
