var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sn-modal",
    class: (_class = {}, _class["sn-modal--".concat(_vm.size)] = !!_vm.size, _class)
  }, [_c('div', {
    staticClass: "sn-modal__head"
  }, [_c('div', {
    staticClass: "sn-modal__title"
  }, [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]), _vm._v(" "), !_vm.noClose ? _c('div', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    staticClass: "sn-modal__close",
    on: {
      "click": function click($event) {
        return _vm.$emit('modal-close');
      }
    }
  }) : _vm._e()]), _vm._v(" "), _vm.inBody ? [_c('div', {
    staticClass: "sn-modal__body"
  }, [_vm._t("default")], 2)] : [_vm._t("default")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }