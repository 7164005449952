var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "carousel",
    staticClass: "s-insta-carousel owl-carousel"
  }, _vm._l(_vm.posts, function (post, index) {
    return _c('a', {
      key: index,
      staticClass: "s-insta-block",
      attrs: {
        "href": post.permalink,
        "target": "_blank"
      }
    }, [_c('div', {
      staticClass: "s-insta-block__header"
    }, [_c('div', {
      staticClass: "s-insta-block__header-logo"
    }, [_c('img', {
      attrs: {
        "src": "".concat(_vm.staticUrl, "/images/insta_logo.jpg")
      }
    })]), _vm._v(" "), _c('div', {
      staticClass: "flex-grow-1 s-bold"
    }, [_vm._v(_vm._s(post.username))]), _vm._v(" "), _c('div', {
      staticClass: "s-c-grey s-bold"
    }, [_vm._v("\n        " + _vm._s(post.convertedDate) + "\n      ")])]), _vm._v(" "), _c('div', {
      staticClass: "s-insta-block__body"
    }, [post.media_type !== 'VIDEO' ? _c('img', {
      staticClass: "s-insta-block__body-img",
      attrs: {
        "src": post.media_url
      }
    }) : _c('div', {
      staticClass: "s-insta-block__body-vid"
    }, [_c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [_c('button', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !post.play,
        expression: "!post.play"
      }],
      staticClass: "s-insta-block__body-play s-btn s-btn--thm-white",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.play(post);
        }
      }
    }, [_c('div', {
      staticClass: "s-ico s-ico--thm-control-play"
    })])]), _vm._v(" "), _c('video', {
      ref: "vid".concat(post.id),
      refInFor: true,
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.pause(post);
        }
      }
    }, [_c('source', {
      attrs: {
        "src": post.media_url,
        "type": "video/mp4"
      }
    })])], 1), _vm._v(" "), post.caption ? _c('div', {
      staticClass: "s-insta-block__body-text"
    }, [_vm._v("\n        " + _vm._s(_vm.cutString(post.caption)) + "\n      ")]) : _vm._e()])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }