var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.modals, function (item, index) {
    return _c('q-dialog', {
      key: index,
      attrs: {
        "value": _vm.modalsActive[index],
        "persistent": !item.notPersistent
      },
      on: {
        "hide": function hide($event) {
          return _vm.onHide(index);
        }
      }
    }, [_c('VModal', {
      attrs: {
        "size": item.size ? String(item.size) : 'medium',
        "title": item.title ? item.title : '',
        "in-body": item.inBody !== undefined ? item.inBody : true
      }
    }, [item.component ? _c(item.component, _vm._g(_vm._b({
      tag: "component"
    }, 'component', item.binds ? item.binds : {}, false), _vm.componentEvents(item, index))) : _vm._e()], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }