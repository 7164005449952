import Vue from 'vue';

import Top from './table-top';
import TableHeader from './table-header';
import TableBody from './table-body';
import Bottom from './table-bottom';
import TableGrid from './table-grid';

import Sort from './table-sort';
import Filter from './table-filter';
import Pagination from './table-pagination';
import RowSelection from './table-row-selection';
import ColumnSelection from './table-column-selection';
import FullscreenMixin from '../../mixins/fullscreen';

export default Vue.extend({
  name: 'QTable',

  mixins: [
    FullscreenMixin,
    Top,
    TableHeader,
    TableBody,
    Bottom,
    TableGrid,
    Sort,
    Filter,
    Pagination,
    RowSelection,
    ColumnSelection,
  ],

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    rowKey: {
      type: String,
      default: 'id',
    },

    columns: Array,
    loading: Boolean,
    binaryStateSort: Boolean,

    title: String,

    hideHeader: Boolean,
    hideBottom: Boolean,

    grid: Boolean,
    gridHeader: Boolean,

    dense: Boolean,
    flat: Boolean,
    bordered: Boolean,
    square: Boolean,
    separator: {
      type: String,
      default: 'horizontal',
      validator: v => ['horizontal', 'vertical', 'cell', 'none'].includes(v),
    },
    wrapCells: Boolean,

    noDataLabel: String,
    noResultsLabel: String,
    loadingLabel: String,
    selectedRowsLabel: Function,
    rowsPerPageLabel: String,
    rowsPerPage: Number,
    paginationLabel: Function,

    color: {
      type: String,
      default: 'grey-8',
    },

    tableStyle: [String, Array, Object],
    tableClass: [String, Array, Object],
    tableHeaderStyle: [String, Array, Object],
    tableHeaderClass: [String, Array, Object],
    cardStyle: [String, Array, Object],
    cardClass: [String, Array, Object],

    dark: Boolean,
    checkboxLabel: {
      type: String,
      default: '',
    },
    leftLabel: Boolean,
  },

  data() {
    const { rowsPerPage } = this;
    return {
      rowsExpanded: {},
      innerPagination: {
        sortBy: null,
        descending: false,
        page: 1,
        rowsPerPage: rowsPerPage || 10,
      },
    };
  },

  computed: {
    computedData() {
      let rows = this.data.slice().map((row, i) => {
        row.__index = i + 1;
        return row;
      });

      if (rows.length === 0) {
        return {
          rowsNumber: 0,
          rows: [],
        };
      }

      if (this.isServerSide === true) {
        return { rows };
      }

      const { sortBy, descending, rowsPerPage } = this.computedPagination;

      if (this.filter) {
        rows = this.filterMethod(rows, this.filter, this.computedCols, this.getCellValue);
      }

      if (this.columnToSort) {
        rows = this.sortMethod(rows, sortBy, descending);
      }

      const rowsNumber = rows.length;

      if (rowsPerPage) {
        rows = rows.slice(this.firstRowIndex, this.lastRowIndex);
      }

      return { rowsNumber, rows };
    },

    computedRows() {
      return this.computedData.rows;
    },

    computedRowsNumber() {
      return this.isServerSide === true
        ? this.computedPagination.rowsNumber || 0
        : this.computedData.rowsNumber;
    },

    nothingToDisplay() {
      return this.computedRows.length === 0;
    },

    isServerSide() {
      return this.computedPagination.rowsNumber !== void 0;
    },

    cardDefaultClass() {
      return ` sn-table__card${
        this.dark === true ? ' sn-table__card--dark' : ''
      }${this.square === true ? ' sn-table--square' : ''
      }${this.flat === true ? ' sn-table--flat' : ''
      }${this.bordered === true ? ' sn-table--bordered' : ''}`;
    },

    containerClass() {
      return `sn-table__container sn-table--${this.separator}-separator${
        this.grid === true ? ' sn-table--grid' : this.cardDefaultClass
      }${this.dark === true ? ' sn-table--dark' : ''
      }${this.dense === true ? ' sn-table--dense' : ''
      }${this.wrapCells === false ? ' sn-table--no-wrap' : ''
      }${this.inFullscreen === true ? ' s-pos-fullscreen sn--scroll' : ''}`;
    },
  },

  methods: {
    requestServerInteraction(prop = {}) {
      this.$nextTick(() => {
        this.$emit('request', {
          pagination: prop.pagination || this.computedPagination,
          filter: prop.filter || this.filter,
          getCellValue: this.getCellValue,
        });
      });
    },

    getBody(h) {
      if (this.grid === true) {
        return this.getGridBody(h);
      }

      return h('div', {
        staticClass: 'sn-table__middle sn--scroll',
        class: this.tableClass,
        style: this.tableStyle,
      }, [
        h('table', { staticClass: 'sn-table' }, [
          this.hideHeader !== true ? this.getTableHeader(h) : null,
          this.getTableBody(h),
        ]),
      ]);
    },
  },

  render(h) {
    const data = { staticClass: this.containerClass };

    if (this.grid === false) {
      data.class = this.cardClass;
      data.style = this.cardStyle;
    }

    return h('div', data, [
      this.getTop(h),
      this.grid === true ? this.getGridHeader(h) : null,
      this.getBody(h),
      this.getBottom(h),
    ]);
  },
});
