import Vue from 'vue';
import _ from 'lodash';
import EventBus from '@common/plugins/UserEventBus/EventBus';
import * as Sentry from '@sentry/browser';

class UserEventBus {
  constructor() {
    this.timers = {};
    this.root = '/event-bus/';
    this.eventBus = EventBus.create();
    this.timer = null;
    this.nRepeet = 0;
  }

  start() {
    this.timer = setTimeout(() => {
      this.send();
    }, 20000);
  }

  /**
   * Adds listener to EventBus
   * @param {string|NamedEvent} event The name or NameEvent instance of the event to listen for
   * @param {function} callback Callback to call when event was triggered
   * @param  {...*} args Any number of args to be passed to the callback
   */
  on(event, callback, ...args) {
    this.eventBus.addEventListener(event, callback, ...args);
  }

  async send() {
    const config = {
      responseType: 'json',
      method: 'GET',
      headers: {},
    };
    try {
      /**
       * @var Response
       */
      const response = await Vue.http.get(
        `${this.root}news`,
        config,
      );
      if (response.status === 200) {
        _.forEach(response.data, (event) => {
          this.eventBus.dispatch(event.name, event.payload);
        });
      }
      this.start();
    } catch (e) {
      if (this.nRepeet < 10) {
        this.nRepeet = this.nRepeet + 1;

        this.start();
      }
      if (this.nRepeet === 0) {
        Sentry.captureException(e);
      }
    }
  }

  stop() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
  }
}

export default UserEventBus;
