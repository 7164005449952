import VueResource from 'vue-resource';
import auth from '@common/src/Authentication';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

export default function install(Vue) {
  Vue.use(VueResource);
  auth.removeToken();

  Vue.http.interceptors.push((request) => {
    // request.credentials = true;//Нужно если будет CORS
    if (window.core_project.organizationId) {
      request.headers.set('X-Organization-Id', String(window.core_project.organizationId));
    }
    if (window.core_project.roleKey) {
      request.headers.set('X-Role-Key', String(window.core_project.roleKey));
    }
    if (window.core_project.locality) {
      request.headers.set('X-LocalityId', String(window.core_project.locality));
    }
    if (window.core_project.department) {
      request.headers.set('X-DepartmentId', String(window.core_project.department));
    }
    if (window.core_project.region) {
      request.headers.set('X-RegionId', String(window.core_project.region));
    }
    if (request.method.toLowerCase() !== 'get') {
      request.headers.set('X-CSRF-TOKEN', window.core_project.csrfToken);
    }
    const token = auth.getToken();
    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }
  });

  axios.interceptors.request.use((request) => {
    // request.credentials = true;//Нужно если будет CORS
    if (window.core_project.organizationId) {
      request.headers['X-Organization-Id'] = String(window.core_project.organizationId);
    }
    if (window.core_project.roleKey) {
      request.headers['X-Role-Key'] = String(window.core_project.roleKey);
    }
    if (window.core_project.locality) {
      request.headers['X-LocalityId'] = String(window.core_project.locality);
    }
    if (window.core_project.department) {
      request.headers['X-DepartmentId'] = String(window.core_project.department);
    }
    if (window.core_project.region) {
      request.headers['X-RegionId'] = String(window.core_project.region);
    }
    if (request.method.toLowerCase() !== 'get') {
      request.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
    }
    const token = auth.getToken();
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });
  // outside state that lets us know if we need to refresh or not
  let allowRefresh = true;

  Vue.http.interceptors.push((request, next) => {
    next((response) => {
      // are we allowed to refresh AND is this a 401?
      if (response.status === 401) {
        // we can only allow them to refresh once,
        // so turn this toggle off so they will be redirected if
        // the refresh response is also a 401
        if (allowRefresh) {
          allowRefresh = false;
          return auth.refresh().then((result) => {
            auth.setToken(result.data.access_token);
            // TODO на будущее когда будет вход только по api
            // auth.initRefreshToken();
            allowRefresh = true;
            request.headers.set('Authorization', `Bearer ${result.data.access_token}`);

            return Vue.http(request).then(data => data);
          }, (refreshTokenReject) => {
            if (refreshTokenReject.status === 419) {
              window.location.reload();
            }
          });
        }
        return new Promise(((resolve, reject) => {
          setTimeout(() => {
            const token = auth.getToken();
            request.headers.set('Authorization', `Bearer ${token}`);

            resolve(Vue.http(request).then(data => data, () => response));
          }, 1000);
        }));
      }
    });
  });
  axios.interceptors.response.use(res => res, async (err) => {
    const { response } = err;
    const originalRequest = err.config;

    if (!response) {
      Sentry.captureException(err);
    }

    if (response.status === 401) {
      // we can only allow them to refresh once,
      // so turn this toggle off so they will be redirected if
      // the refresh response is also a 401
      if (allowRefresh) {
        allowRefresh = false;
        const data = await auth.refresh().catch((reject) => {
          if (reject.status === 419) {
            window.location.reload();
          }
        });
        auth.setToken(data.data.access_token);
        allowRefresh = true;

        originalRequest.headers.Authorization = `Bearer ${data.data.access_token}`;

        return axios(originalRequest);
      }
      return new Promise(((resolve, reject) => {
        setTimeout(() => {
          const token = auth.getToken();
          originalRequest.headers.Authorization = `Bearer ${token}`;


          resolve(axios(originalRequest));
        }, 1000);
      }));
    }
    return Promise.reject(err);
  });
}
