// extracted from node_modules/date-fns/locale/ru/build_distance_in_words_locale/index.js

function declension (scheme, count) {
  // scheme for count=1 exists
  if (scheme.one !== undefined && count === 1) {
    return scheme.one
  }

  var rem10 = count % 10
  var rem100 = count % 100

  // 1, 21, 31, ...
  if (rem10 === 1 && rem100 !== 11) {
    return scheme.singularNominative.replace('{{count}}', count)

  // 2, 3, 4, 22, 23, 24, 32 ...
  } else if ((rem10 >= 2 && rem10 <= 4) && (rem100 < 10 || rem100 > 20)) {
    return scheme.singularGenitive.replace('{{count}}', count)

  // 5, 6, 7, 8, 9, 10, 11, ...
  } else {
    return scheme.pluralGenitive.replace('{{count}}', count)
  }
}

function buildLocalizeTokenFn (scheme) {
  return function (count, options) {
    if (options.addSuffix) {
      if (options.comparison > 0) {
        if (scheme.future) {
          return declension(scheme.future, count)
        } else {
          return 'через ' + declension(scheme.regular, count)
        }
      } else {
        if (scheme.past) {
          return declension(scheme.past, count)
        } else {
          return declension(scheme.regular, count) + ' назад'
        }
      }
    } else {
      return declension(scheme.regular, count)
    }
  }
}

function buildDistanceInWordsLocale () {
  var distanceInWordsLocale = {
    lessThanXSeconds: buildLocalizeTokenFn({
      regular: {
        one: 'меньше секунды',
        singularNominative: 'меньше {{count}} с.',
        singularGenitive: 'меньше {{count}} с.',
        pluralGenitive: 'меньше {{count}} с.'
      },
      future: {
        one: 'меньше, чем через секунду',
        singularNominative: 'меньше, чем через {{count}} с.',
        singularGenitive: 'меньше, чем через {{count}} с.',
        pluralGenitive: 'меньше, чем через {{count}} с.'
      }
    }),

    xSeconds: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} с.',
        singularGenitive: '{{count}} с.',
        pluralGenitive: '{{count}} с.'
      },
      past: {
        singularNominative: '{{count}} с. назад',
        singularGenitive: '{{count}} с. назад',
        pluralGenitive: '{{count}} с. назад'
      },
      future: {
        singularNominative: 'через {{count}} с.',
        singularGenitive: 'через {{count}} с.',
        pluralGenitive: 'через {{count}} с.'
      }
    }),

    halfAMinute: function (_, options) {
      if (options.addSuffix) {
        if (options.comparison > 0) {
          return 'через полминуты'
        } else {
          return 'полминуты назад'
        }
      }

      return 'полминуты'
    },

    lessThanXMinutes: buildLocalizeTokenFn({
      regular: {
        one: 'меньше минуты',
        singularNominative: 'меньше {{count}} м.',
        singularGenitive: 'меньше {{count}} м.',
        pluralGenitive: 'меньше {{count}} м.'
      },
      future: {
        one: 'меньше, чем через минуту',
        singularNominative: 'меньше, чем через {{count}} м.',
        singularGenitive: 'меньше, чем через {{count}} м.',
        pluralGenitive: 'меньше, чем через {{count}} м.'
      }
    }),

    xMinutes: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} м.',
        singularGenitive: '{{count}} м.',
        pluralGenitive: '{{count}} м.'
      },
      past: {
        singularNominative: '{{count}} м. назад',
        singularGenitive: '{{count}} м. назад',
        pluralGenitive: '{{count}} м. назад'
      },
      future: {
        singularNominative: 'через {{count}} м.',
        singularGenitive: 'через {{count}} м.',
        pluralGenitive: 'через {{count}} м.'
      }
    }),

    aboutXHours: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} ч.',
        singularGenitive: '{{count}} ч.',
        pluralGenitive: '{{count}} ч.'
      },
      future: {
        singularNominative: 'через {{count}} ч.',
        singularGenitive: 'через {{count}} ч.',
        pluralGenitive: 'через {{count}} ч.'
      }
    }),

    xHours: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} ч.',
        singularGenitive: '{{count}} ч.',
        pluralGenitive: '{{count}} ч.'
      }
    }),

    xDays: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} д.',
        singularGenitive: '{{count}} д.',
        pluralGenitive: '{{count}} д.'
      }
    }),

    aboutXMonths: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} мес.',
        singularGenitive: '{{count}} мес.',
        pluralGenitive: '{{count}} мес.'
      },
      future: {
        singularNominative: 'через {{count}} мес.',
        singularGenitive: 'через {{count}} мес.',
        pluralGenitive: 'через {{count}} мес.'
      }
    }),

    xMonths: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} мес.',
        singularGenitive: '{{count}} мес.',
        pluralGenitive: '{{count}} мес.'
      }
    }),

    aboutXYears: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} г.',
        singularGenitive: '{{count}} лет',
        pluralGenitive: '{{count}} лет'
      },
      future: {
        singularNominative: 'через {{count}} г.',
        singularGenitive: 'через {{count}} г.',
        pluralGenitive: 'через {{count}} лет'
      }
    }),

    xYears: buildLocalizeTokenFn({
      regular: {
        singularNominative: '{{count}} год',
        singularGenitive: '{{count}} года',
        pluralGenitive: '{{count}} лет'
      }
    }),

    overXYears: buildLocalizeTokenFn({
      regular: {
        singularNominative: 'больше {{count}} г.',
        singularGenitive: 'больше {{count}} лет',
        pluralGenitive: 'больше {{count}} лет'
      },
      future: {
        singularNominative: 'больше, чем через {{count}} г.',
        singularGenitive: 'больше, чем через {{count}} г.',
        pluralGenitive: 'больше, чем через {{count}} лет'
      }
    }),

    almostXYears: buildLocalizeTokenFn({
      regular: {
        singularNominative: 'почти {{count}} год',
        singularGenitive: 'почти {{count}} года',
        pluralGenitive: 'почти {{count}} лет'
      },
      future: {
        singularNominative: 'почти через {{count}} г.',
        singularGenitive: 'почти через {{count}} г.',
        pluralGenitive: 'почти через {{count}} лет'
      }
    })
  }

  function localize (token, count, options) {
    options = options || {}
    return distanceInWordsLocale[token](count, options)
  }

  return {
    localize: localize
  }
}

// module.exports = buildDistanceInWordsLocale
// custom workaround
module.exports = { distanceInWords: buildDistanceInWordsLocale(), };
