<template>
  <h5 class="s-combine-title mb-30">
    <span class="s-combine-title__label s-upper">{{ text }}</span>
    <!--suppress CheckEmptyScriptTag -->
    <span class="s-combine-title__line" />
  </h5>
</template>

<script>
export default {
  name: 'VSubheader',
  props: {
    text: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style scoped>

</style>
