import AlignMixin from '../../mixins/align';
import RippleMixin from '../../mixins/ripple';

const sizes = {
  xs: 10,
  sm: 12,
  md: 16,
  lg: 18,
  xl: 22,
};

export default {
  mixins: [RippleMixin, AlignMixin],

  props: {
    type: String,
    to: [Object, String],
    replace: Boolean,
    useFaIcons: Boolean,

    label: [Number, String],
    icon: String,
    iconRight: String,

    round: Boolean,
    squire: Boolean,
    outline: Boolean,
    bordered: Boolean,
    flat: Boolean,
    unelevated: Boolean,
    rounded: Boolean,
    push: Boolean,
    glossy: Boolean,

    size: [String, Number],
    width: String,
    labelWider: Boolean,
    fab: Boolean,
    fabMini: Boolean,

    color: String,
    textColor: String,
    noCaps: Boolean,
    noWrap: Boolean,
    dense: Boolean,
    ellipsis: Boolean,

    tabindex: [Number, String],

    align: { default: 'center' },
    stack: Boolean,
    stretch: Boolean,
    loading: {
      type: Boolean,
      default: null,
    },
    disable: Boolean,
  },

  computed: {
    style() {
      const style = {};

      if (this.fab === false && this.fabMini === false && this.size) {
        style.fontSize = this.size in sizes ? `${sizes[this.size]}px` : this.size;
      }

      if (this.width) {
        style.minWidth = `${this.width}rem`;
      }

      return style;
    },

    isRound() {
      return this.round === true || this.fab === true || this.fabMini === true;
    },

    isSquire() {
      return this.squire === true;
    },

    isDisabled() {
      return this.disable === true || this.loading === true;
    },

    computedTabIndex() {
      return this.isDisabled === true ? -1 : this.tabindex || 0;
    },

    hasRouterLink() {
      return this.disable !== true && this.to !== undefined && this.to !== null && this.to !== '';
    },

    isLink() {
      return this.type === 'a' || this.hasRouterLink === true;
    },

    design() {
      if (this.flat === true) return 'flat';
      if (this.outline === true) return 'outline';
      if (this.push === true) return 'push';
      if (this.unelevated === true) return 'unelevated';
      return 'standard';
    },

    attrs() {
      const att = { tabindex: this.computedTabIndex };
      if (this.type !== 'a') {
        att.type = this.type || 'button';
      }
      if (this.hasRouterLink === true && this.$router) {
        att.href = this.$router.resolve(this.to).href;
      } else {
        att.href = this.to;
      }
      if (this.isDisabled === true) {
        att.disabled = true;
      }
      return att;
    },

    classes() {
      let colors;

      if (this.color !== undefined) {
        if (this.flat === true || this.outline === true) {
          colors = `s-c-${this.textColor || this.color}`;
        } else {
          colors = `s-b-${this.color} s-c-${this.textColor || 'white'}`;
        }
      } else if (this.textColor) {
        colors = `s-c-${this.textColor}`;
      }

      return `sn-btn--${this.design} sn-btn--${this.isRound === true ? 'round' : this.isSquire === true ? 'squire' : 'rectangle'}${
        colors !== undefined ? ` ${colors}` : ''
      }${this.isDisabled !== true ? ' sn-focusable sn-hoverable' : ' sn--disabled'
      }${this.fab === true ? ' sn-btn--fab' : (this.fabMini === true ? ' sn-btn--fab-mini' : '')
      }${this.noCaps === true ? ' sn-btn--no-uppercase' : ''
      }${this.ellipsis === true ? ' sn-btn--ellipsis' : ''
      }${this.bordered === true ? ' sn-btn--bordered' : ''
      }${this.rounded === true ? ' sn-btn--rounded' : ''
      }${this.dense === true ? ' sn-btn--dense' : ''
      }${this.stretch === true ? ' sn--no-border-radius sn--self-stretch' : ''
      }${!this.label ? ' sn-btn--without-label' : ''
      }${this.glossy === true ? ' sn--glossy' : ''}`;
    },

    innerClasses() {
      return this.alignClass + (this.stack === true ? ' sn-btn__content--stack sn--column' : ' sn--row')
        + (this.noWrap === true ? ' sn--no-wrap sn--text-no-wrap' : '')
        + (this.loading === true ? ' sn-btn__content--hidden' : '');
    },
  },
};
